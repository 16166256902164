<app-layout>
    <mat-toolbar class="page-header" role="heading">
        <h1>จัดการข้อมูลลูกหนี้</h1>
    </mat-toolbar>


    <mat-card-content>
        <div>
            <mat-card>
                <form #form="ngForm" (ngSubmit)="onSubmitSearch()">
                    <mat-card-content>
                        <h3>ข้อมูลจาก SAP</h3>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <div fxLayout="row" fxLayoutAlign="none">
                                <div fxLayout="row" fxLayoutAlign="none center" fxFlex="50%">
                                    <label>Business Partner</label>
                                    <mat-form-field floatLabel='never' appearance="outline">
                                        <input matInput type="text" ngModel name="businessPartner">
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="none center" fxFlex="50%"
                                    style="padding-left: 50px;">
                                    <section class="example-section">
                                        <mat-checkbox style="margin-left: 10px;" class="example-margin" ngModel
                                            name="matched">Match
                                        </mat-checkbox>
                                        <mat-checkbox style="margin-left: 10px;" ngModel name="not_matched">Unmatch
                                        </mat-checkbox>
                                    </section>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="none">
                                <div fxLayout="row" fxLayoutAlign="none center" fxFlex="50%">
                                    <label>Name 1 of Organization</label>
                                    <mat-form-field floatLabel='never' appearance="outline">
                                        <input matInput type="text" ngModel name="name1OfOrganization">
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="none center" fxFlex="50%">
                                    <label>TaxNumber</label>
                                    <mat-form-field floatLabel='never' appearance="outline">
                                        <input matInput type="text" ngModel name="taxNumber">
                                    </mat-form-field>
                                </div>
                            </div>
                            <h3>ข้อมูลจาก Limousine</h3>
                            <div fxLayout="column" fxLayoutAlign="center stretch">
                                <div fxLayout="row" fxLayoutAlign="none">
                                    <div fxLayout="row" fxLayoutAlign="none center" fxFlex="50%">
                                        <label class="col-sm-4 col-form-label">Company Code
                                        </label>
                                        <mat-form-field floatLabel='never' appearance="outline">
                                            <input matInput type="text" ngModel name="companyCode">

                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="none center" fxFlex="50%">
                                        <label class="col-sm-4 col-form-label">Company Name

                                        </label>
                                        <mat-form-field floatLabel='never' appearance="outline">
                                            <input matInput type="text" ngModel name="CompanyName">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="none">
                                    <div fxLayout="row" fxLayoutAlign="none center" fxFlex="50%">

                                        <label class="col-sm-4 col-form-label">Client Code</label>
                                        <mat-form-field floatLabel='never' appearance="outline">
                                            <input matInput type="text" ngModel name="clientCode">
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="none center" fxFlex="50%">
                                        <label class="col-sm-4 col-form-label">Client Name
                                        </label>
                                        <mat-form-field floatLabel='never' appearance="outline">
                                            <input matInput type="text" ngModel name="clientName">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <button mat-raised-button color="primary" type="submit">ค้นหา
                            </button>
                        </div>
                    </mat-card-content>
                </form>
            </mat-card>
        </div>
    </mat-card-content>

    <mat-card-content>
        <div>
            <mat-card>
                <mat-card-content>

                    <table mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="PARTNER">
                            <th mat-header-cell *matHeaderCellDef>Business Partner</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <span class="table-cell">{{element.PARTNER}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="J_1TPBUPL">
                            <th mat-header-cell *matHeaderCellDef>Branch Code/Mail to</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <span class="table-cell">{{element.J_1TPBUPL}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="DESCRIPTION">
                            <th mat-header-cell *matHeaderCellDef>Description</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <span class="table-cell">{{element.DESCRIPTION}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="NAME_ORG1">
                            <th mat-header-cell *matHeaderCellDef>Name 1 of Organization</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <span class="table-cell">{{element.NAME_ORG1}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="TAXNUMXL">
                            <th mat-header-cell *matHeaderCellDef>Tax Number</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <span class="table-cell">{{element.TAXNUMXL}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="comp_code">
                            <th mat-header-cell *matHeaderCellDef>Company Code</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <span class="table-cell">{{element.comp_code}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="comp_name">
                            <th mat-header-cell *matHeaderCellDef>Company Name</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <span class="table-cell">{{element.comp_name}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="client_code">
                            <th mat-header-cell *matHeaderCellDef>Client Code</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <span class="table-cell">{{element.client_code}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="client_name">
                            <th mat-header-cell *matHeaderCellDef>Client Name</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <span class="table-cell">{{element.client_name}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="manage">
                            <th mat-header-cell *matHeaderCellDef>Manage</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <button mat-raised-button color="primary" (click)="onEdit(element)">Edit</button> </td>
                        </ng-container>
                        <ng-container matColumnDef="noRecord">
                            <td mat-footer-cell *matFooterCellDef [attr.colspan]="dataColumns.length">No records found.
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="dataColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: dataColumns;"></tr>
                        <ng-template [ngIf]="true">
                            <tr mat-footer-row *matFooterRowDef="['noRecord']"
                                [ngClass]="{'hide' : dataSource.data.length > 0}"></tr>
                        </ng-template>
                    </table>

                    <mat-paginator class="pagination" [length]="totalElements" [pageSize]="this.tableOption.pageSize"
                        [pageSizeOptions]="pageSizeOptions" (page)="changePage($event)">
                    </mat-paginator>

                </mat-card-content>
            </mat-card>
        </div>
    </mat-card-content>
</app-layout>