import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { routes } from 'src/app/consts';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth/services';

@Injectable({
  providedIn: 'root'
})
export class CancelService {

  public routers: typeof routes = routes;

  constructor(
    private _http: HttpClient,
    private userService: AuthService,
    private router: Router
  ) { }

  async getcancelList() : Promise<any[]> {
    return new Promise(async (reslove, reject) => {
      try {
        const response = await this._http.get<any>(`${environment.apiUrl}/common/cancel`).toPromise();
        reslove(response.result);
      } catch (e) {
        reject(this.getErrorMessage(e));
      }
    });
  }

  getErrorMessage(e) {
    console.log(e)
    let message = null;
    if (e.status === 401) {
      message = `Token expired กรุณา login ใหม่`;
      this.userService.signOut();
      this.router.navigate([this.routers.LOGIN]);
    } else {
      message = e.error.message;
    }
    return message;
  }
}
