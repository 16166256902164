
<mat-toolbar class="header" *ngIf="isPage">
  <div class="header__title">
    <button class="header__title-button" mat-mini-fab (click)="openMenuHome()">
      <mat-icon class="header__title-button-icon">arrow_back</mat-icon>
    </button>
    <img class="auth-page__logo-img" src="./assets/aot-limo-logo2.png" alt="logo" style="background: white;
    padding: 10px;
    border-radius: 10px;">
  </div>

  <div class="header-toolbar">
    <app-user [user]="user$ | async" (signOut)="signOut()"></app-user>
  </div>
</mat-toolbar>

<mat-toolbar class="header" *ngIf="!isPage">
  <div class="header__title">
    <button class="header__title-button" *ngIf="widthScreen < 500" mat-mini-fab (click)="openMenu()">
      <mat-icon *ngIf="!isMenuOpened" class="header__title-button-icon">menu</mat-icon>
      <mat-icon *ngIf="isMenuOpened" class="header__title-button-icon">arrow_back</mat-icon>
    </button>
    <img class="auth-page__logo-img" src="./assets/aot-limo-logo2.png" alt="logo" style="background: white;
    padding: 10px;
    border-radius: 10px;">
  </div>

  <div class="header-toolbar">
    <app-user [user]="user$ | async" (signOut)="signOut()"></app-user>
  </div>
</mat-toolbar>