<app-layout>
    <mat-toolbar class="page-header" role="heading">
      <h1>เชื่อมต่อกับ SAP</h1>
    </mat-toolbar>
  
    <mat-card-content class="typography-content">
      <div class="typography-content__wrapper">
        <mat-card class="typography-content__item-wrapper">
          <div class="typography-content__headings">
            <ol>
              <li *ngFor="let item of menuList">{{item.parent}}
                  <ul *ngFor="let data of item.children" ><a href="javascript:void(0)" (click)="navigateTo(data)">{{data.name}}</a></ul>
              </li>
          </ol>
          </div>
        </mat-card>
      </div>
    </mat-card-content>
  </app-layout>
  