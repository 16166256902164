import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { User } from '../models';
import { resolve } from 'dns';
import { storage } from 'src/app/consts/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public storage: typeof storage = storage;
  constructor(
    private _http: HttpClient
  ) { }

  public async login(username, password): Promise<any> {
    return new Promise(async (reslove, rejects) => {
      try {
        const data = {
          username,
          password
        };
        const response = await this._http.post<any>(`${environment.apiUrl}/user/login`, data, { observe: 'response' }).toPromise();
        if (response.body.message === 'OK') {
          const userdata = Object.assign(response.body.data || {}, { username });
          localStorage.setItem(storage.USER, JSON.stringify(userdata));
          localStorage.setItem(storage.TOKEN, response.headers.get('token'));
          reslove();
        } else {
          throw response;
        }
      } catch (e) {
        rejects(e);
      }

    });
  }

  public signOut(): void {
    localStorage.removeItem(storage.USER);
    localStorage.removeItem(storage.TOKEN);
  }

  public getUser(): Observable<User> {

    const user = JSON.parse(localStorage.getItem(storage.USER));
    return of({
      name: user.firstname_th || user.username,
      lastName: user.firstname_th ? user.lastname_th : ''
    });
  }
}
