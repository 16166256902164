import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';  
import { Observable } from 'rxjs';  

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private subject = new Subject<any>();
  constructor() { }

  sendText(message: string) {  
    this.subject.next({ text: message });  
  }  
  
  getText(): Observable<any> {  
    return this.subject.asObservable();  
  }
}
