import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services';
import { ErrorToastrComponent } from 'src/app/shared/component/error-toastr/error-toastr.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  @Output() sendLoginForm = new EventEmitter<void>();
  public form: FormGroup;
  public flatlogicEmail = '';
  public flatlogicPassword = '';
  public isLogining = false;

  constructor(
    private service: AuthService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.form = new FormGroup({
      username: new FormControl(this.flatlogicEmail, [Validators.required]),
      password: new FormControl(this.flatlogicPassword, [Validators.required])
    });
  }

  public async login() {
    this.isLogining = true;
    try {
      if (this.form.valid) {
        await this.service.login(this.form.get('username').value, this.form.get('password').value);
        this.sendLoginForm.emit();
      }
    } catch (e) {
      console.log(e)
      this.toastr.show(
        e.error.message,
        null,
        {
          positionClass: 'toast-top-right',
          toastComponent: ErrorToastrComponent,
          timeOut: 3000,
          tapToDismiss: false
        }
      );
    } finally {
      this.isLogining = false;
    }
  }
}
