import { Component, OnInit, OnChanges, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EditDetailComponent } from '../../component/edit-detail/edit-detail.component';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SuccessToastComponent } from 'src/app/shared/component/success-toast/success-toast.component';
import { ManageService } from '../../service/manage.service';
import { LoadingComponent } from 'src/app/shared/component/loading/loading.component';
import { ErrorToastrComponent } from 'src/app/shared/component/error-toastr/error-toastr.component';

@Component({
  templateUrl: './mange-page.component.html',
  styleUrls: ['./mange-page.component.scss']
})
export class MangePageComponent implements OnInit, AfterViewInit {

  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(NgForm, { static: false }) form: NgForm;
  dataColumns = ['PARTNER', 'J_1TPBUPL', 'DESCRIPTION', 'NAME_ORG1', 'TAXNUMXL', 'comp_code', 'comp_name', 'client_code', 'client_name', 'manage'];
  public clientCodeList: any = [];
  public companyCodeList: any = [];

  totalElements: any;
  tableOption: any = {
    pageNumber: 0,
    pageSize: 10,
    sortDirection: 'ASC',
    sortProperties: '',
  };
  pageSizeOptions: any[] = [10, 20, 30];

  constructor(
    public dialog: MatDialog,
    private toastr: ToastrService,
    private _manageService: ManageService
  ) { }

  async ngOnInit() {
    this.dataSource.data = [];
    this.companyCodeList = await this._manageService.getClient();
  }

  async getCompanyName(event) {
    this.clientCodeList = [];
    this.clientCodeList = await this._manageService.getSapcustomer(event.target.value);
  }


  async onEdit(element) {
    let config = new MatDialogConfig();

    element.companyCodeList = this.companyCodeList.sort((a,b) => (a.comp_code > b.comp_code) ? 1 : ((b.comp_code > a.comp_code) ? -1 : 0));
    element.clientCodeList = await this._manageService.getSapcustomer(element.comp_id);
    config = {
      height: '80vh',
      width: '100vw',
      panelClass: 'full-screen-modal',
      disableClose: true,
      data: element
    };
    let dialogRef = this.dialog.open(EditDetailComponent, config);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        element.client_code = result.clientCode;
        element.client_id = result.clientId;
        element.client_name = result.clientName;

        element.comp_code = result.companyCode;
        element.comp_id = result.companyId;
        element.comp_name = result.companyName;

        this.toastr.show(
          'บันทึกสำเร็จ',
          null,
          {
            positionClass: 'toast-top-right',
            toastComponent: SuccessToastComponent,
            timeOut: 3000,
            tapToDismiss: false
          }
        );
      }
    });

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.form.form.get('matched').setValue(true);
      this.form.form.get('not_matched').setValue(true);
    }, 100);

    this.dataSource.paginator = this.paginator;
  }

  changePage(pageObj: PageEvent) {
    console.log(pageObj);
    if (pageObj) {
      this.tableOption.pageNumber = pageObj.pageIndex;
      this.tableOption.pageSize = pageObj.pageSize;
    }
    this.onSubmitSearch(++pageObj.pageIndex);
  }

  async onSubmitSearch(pageNumber = 1) {

    let config = new MatDialogConfig();
    config = {
      panelClass: 'tranparent-modal',
      disableClose: true
    };
    let dialogRef = this.dialog.open(LoadingComponent, config);

    try {
      this.dataSource.data = [];
      const matched = this.form.form.get('matched').value
      const not_matched = this.form.form.get('not_matched').value
      const data = {
        "match_status": this.getMatch(matched, not_matched),
        "partner": this.form.form.get('businessPartner').value,
        "name1": this.form.form.get('name1OfOrganization').value,
        "tax_number": this.form.form.get('taxNumber').value,
        "client_code": this.form.form.get('clientCode').value,
        "company_code": this.form.form.get('companyCode').value,
        "client_name": this.form.form.get('clientName').value,
        "company_name": this.form.form.get('CompanyName').value
      };
      const response = await this._manageService.search(this.tableOption.pageSize, pageNumber, data);
      this.totalElements = response.count;
      this.dataSource = new MatTableDataSource<any>(response.data);
    } catch (e) {
      this.toastr.show(
        e,
        null,
        {
          positionClass: 'toast-top-right',
          toastComponent: ErrorToastrComponent,
          timeOut: 3000,
          tapToDismiss: false
        }
      );
    } finally {
      dialogRef.close();
    }
  }



  getMatch(value1, value2) {
    if (value1 == value2) {
      return 0
    } else if (value1 != true && value2 == true) {
      return 1;
    } else if (value1 == true && value2 != true) {
      return 2;
    }
  }


}
