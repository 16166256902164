<mat-list>
  <mat-list-item routerLink="{{routes.REOPRT}}" routerLinkActive="active">
    <mat-icon>assessment</mat-icon> เชื่อมต่อกับ SAP
  </mat-list-item>
  <mat-list-item routerLink="{{routes.MANAGE}}" routerLinkActive="active">
    <mat-icon>ballot</mat-icon> จัดการข้อมูลลูกหนี้
  </mat-list-item>
  <mat-list-item routerLinkActive="active" (click)="clearNoti()">
    <mat-icon>block</mat-icon><div> รายการ Cancel จาก SAP <span  style="color:#ff4081 ;" *ngIf="user.isCancel">●</span></div>
  </mat-list-item>

</mat-list>