import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { REPORT } from '../../const/report';
import { ErrorToastrComponent } from 'src/app/shared/component/error-toastr/error-toastr.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoadingComponent } from 'src/app/shared/component/loading/loading.component';
import { ReportService } from '../../service/report.service';
import { DateSelectionComponent } from '../../component/date-selection/date-selection.component';
import { ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import {  MessageService } from '../../../../services/message.service';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  templateUrl: './report10.component.html',
  styleUrls: ['./report10.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class Report10Component implements OnInit {
  isPage:any = 'report'; 
  public reportName: string;
  public dataModel = [];
  public dataModel2 = [];
  public reportID: any;
  public selectedDate: any = '';
  public clientList: any = [];
  @ViewChild(NgForm, { static: false }) form: NgForm;
  @ViewChild(DateSelectionComponent, { static: false }) datesSelecter: DateSelectionComponent;
  constructor(
    private route: ActivatedRoute,
    private reportService: ReportService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private message_service: MessageService
  ) { }

  ngOnInit() {
    this.getClientCode();
    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.reportName = REPORT.find(o => o.reportID == params.reportID).reportName;
      this.reportID = params.reportID;
    });
    this.message_service.sendText(this.isPage); 
  }

  async onDateSelected() {

    let config = new MatDialogConfig();
    config = {
      panelClass: 'tranparent-modal',
      disableClose: true
    };
    let dialogRef = this.dialog.open(LoadingComponent, config);
    this.selectedDate = this.form.form.get('date').value;
    try {
      this.dataModel = await this.reportService.getFeetSearch(this.reportID, this.form.form.get('clientID').value, this.selectedDate.year(), this.selectedDate.month() + 1);
      this.dataModel2 = await this.reportService.getFeetLog(this.reportID, this.form.form.get('clientID').value, this.selectedDate.year(), this.selectedDate.month() + 1);
    } catch (e) {
      this.toastr.show(
        e,
        null,
        {
          positionClass: 'toast-top-right',
          toastComponent: ErrorToastrComponent,
          timeOut: 3000,
          tapToDismiss: false
        }
      );
    } finally {
      dialogRef.close();
    }
  }

  async onCallSap() {

    let config = new MatDialogConfig();
    config = {
      panelClass: 'tranparent-modal',
      disableClose: true
    };
    let dialogRef = this.dialog.open(LoadingComponent, config);

    try {
      await this.reportService.callSapFeet(this.reportID, this.form.form.get('clientID').value, this.selectedDate.year(), this.selectedDate.month() + 1);
      this.dataModel2 = await this.reportService.getFeetLog(this.reportID, this.form.form.get('clientID').value, this.selectedDate.year(), this.selectedDate.month() + 1);
      this.dataModel = [];
      this.selectedDate = '';
    } catch (e) {
      this.toastr.show(
        e,
        null,
        {
          positionClass: 'toast-top-right',
          toastComponent: ErrorToastrComponent,
          timeOut: 3000,
          tapToDismiss: false
        }
      );
    } finally {
      dialogRef.close();
    }
  }

  async getClientCode() {
    let config = new MatDialogConfig();
    config = {
      panelClass: 'tranparent-modal',
      disableClose: true
    };
    let dialogRef = this.dialog.open(LoadingComponent, config);
    try {
      this.clientList = await this.reportService.getClientCode();
     // console.log(this.clientList);
      this.clientList.sort((a,b) => (a.client_code > b.client_code) ? 1 : ((b.client_code > a.client_code) ? -1 : 0));
    } catch (e) {
      this.toastr.show(
        e,
        null,
        {
          positionClass: 'toast-top-right',
          toastComponent: ErrorToastrComponent,
          timeOut: 3000,
          tapToDismiss: false
        }
      );
    } finally {
      dialogRef.close();
    }
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.form.form.get('date').value || moment();
    ctrlValue.year(normalizedYear.year());
    this.form.form.get('date').setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.form.form.get('date').value || moment();
    ctrlValue.month(normalizedMonth.month());
    this.form.form.get('date').setValue(ctrlValue);
    datepicker.close();
  }

}
