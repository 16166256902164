<app-layout>
    <mat-toolbar class="page-header" role="heading">
        <h1>{{reportName}}</h1>
    </mat-toolbar>

    <mat-card-content>
        <div>
            <mat-card>
                <form #form="ngForm" (ngSubmit)="onDateSelected()">

                    <div fxLayout="row" fxLayoutAlign="none">
                        <mat-label style="margin: auto 50px; width: 75px;">Client Code</mat-label>
                        <mat-form-field appearance="outline" style="width: 236px;">
                            <select matNativeControl required ngModel name="clientID"
                                style="border: none; width: 236px;">
                                <option *ngFor="let item of clientList" [value]="item.client_id">
                                    {{item.PARTNER+' - '+item.ADR_KIND+' - '+item.client_code}}</option>
                            </select>
                        </mat-form-field>
                    </div>


                    <div fxLayout="row" fxLayoutAlign="none">
                        <mat-label style="margin: auto 50px; width: 75px;">Print Date</mat-label>

                        <mat-form-field floatLabel='never' appearance="outline">
                            <input matInput [matDatepicker]="dp" ngModel name="date" readonly required>
                            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                            <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
                                (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
                            </mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="none">
                        <mat-label style="margin: auto 50px; width: 75px;"></mat-label>
                        <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">ค้นหายอดรวม
                        </button>
                    </div>
                </form>
            </mat-card>
        </div>

        <div>
            <mat-card>
                <mat-card-title>
                    <div fxLayout="row">
                        <div fxLayout="row" fxFlex="90%" fxLayoutAlign="start none">
                            ยอดรวมที่ยังไม่ได้ส่ง
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end none">
                            <button mat-raised-button color="primary" (click)="onCallSap()"
                                [disabled]="dataModel.length <= 0">
                                ส่งข้อมูลให้ SAP
                            </button>
                        </div>
                    </div>
                </mat-card-title>
                <mat-card-content>
                    <app-table-feet [dataModel]="dataModel"></app-table-feet>
                </mat-card-content>
            </mat-card>
        </div>

        <div>
            <mat-card>
                <mat-card-title>
                    Log ที่ส่ง SAP แล้ว
                </mat-card-title>
                <mat-card-content>
                    <app-table-feet-sent [dataModel]="dataModel2"></app-table-feet-sent>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-card-content>

</app-layout>