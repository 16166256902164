import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportMainComponent } from './containers/report-main/report-main.component';
import { Report01Component } from './containers/report01/report01.component';
import { Report04Component } from './containers/report04/report04.component';
import { Report10Component } from './containers/report10/report10.component';
import { Report11Component } from './containers/report11/report11.component';
import { Report06Component } from './containers/report06/report06.component';
import { Report08Component } from './containers/report08/report08.component';


const routes: Routes = [
  {
    path: '',
    component: ReportMainComponent
  }, {
    path: 'report-01',
    component: Report01Component
  }, {
    path: 'report-04',
    component: Report04Component
  }, {
    path: 'report-10',
    component: Report10Component
  }, {
    path: 'report-11',
    component: Report11Component
  }, {
    path: 'report-06',
    component: Report06Component
  }, {
    path: 'report-08',
    component: Report08Component
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
