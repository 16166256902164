<app-layout>
    <mat-toolbar class="page-header" role="heading">
        <h1>{{reportName}}</h1>
    </mat-toolbar>

    <mat-card-content>
        <div>
            <mat-card>
                <app-date-selection (selectedDate)="onDateSelected($event)"></app-date-selection>
            </mat-card>
        </div>

        <div>
            <mat-card>
                <mat-card-title>
                    <div fxLayout="row">
                        <div fxLayout="row" fxFlex="90%" fxLayoutAlign="start none">
                            ยอดรวมที่ยังไม่ได้ส่ง
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end none">
                            <button mat-raised-button color="primary" (click)="onCallSap()" [disabled]="dataModel.length <= 0">
                                ส่งข้อมูลให้ SAP
                            </button>
                        </div>
                    </div>

                </mat-card-title>
                <mat-card-content>
                    <app-table-sap-waiting [dataModel]="dataModel"></app-table-sap-waiting>
                </mat-card-content>
            </mat-card>
        </div>


        <div>
            <mat-card>
                <mat-card-title>
                    Log ที่ส่ง SAP แล้ว
                </mat-card-title>
                <mat-card-content>
                    <app-table-sap-sent [dataModel]="dataModel2"></app-table-sap-sent>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-card-content>

</app-layout>