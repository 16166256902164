import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY"
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};
@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class DateSelectionComponent implements OnInit {

  @Output() selectedDate = new EventEmitter();
  @ViewChild(NgForm, { static: false }) form: NgForm;
  @ViewChild('picker', { static: false }) datePicker: MatDatepicker<Date>;
  constructor() { }

  ngOnInit(): void {
    // setTimeout(() => {
    //   let nDate = new Date((new Date()).valueOf() - 1000*60*60*24);
    //   const data = nDate.getFullYear() + '-' + (nDate.getMonth() + 1) + '-' + (nDate.getDate() - 1);
    //   this.datePicker.select(new Date(data));
    // }, 150);
  }

  onCompleteDate() {
    console.log('onCompleteDate');
    if (this.form.valid) {
      const date = this.onDateChange(this.form.form.get('date').value);
      this.selectedDate.emit(date);
    }
  }

  onDateChange(event: any) {
    const nDate = new Date(event)
    return nDate.getFullYear() + '-' + (nDate.getMonth() + 1) + '-' + nDate.getDate();
  }

  clearDate() {
    this.form.form.get('date').setValue(null);
    this.form.form.get('date').markAsUntouched();
    this.form.form.get('date').setErrors(null);
  }

  setDate(date) {
    this.datePicker.select(date);
    this.selectedDate.emit(date);
  }

}
