export enum routes {
  REOPRT = '/report',
  REPORT01 = '/report-01',
  REPORT04 = '/report-04',
  REPORT10 = '/report-10',
  REPORT11 = '/report-11',
  MANAGE = '/manage',
  CANCEL = '/cancel',
  LOGIN = '/login'
}
