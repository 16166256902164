<app-layout>
    <mat-toolbar class="page-header" role="heading">
        <h1>รายการ Cancel จาก SAP</h1>
    </mat-toolbar>


    <mat-card-content>
        <div>
            <mat-card>
                <mat-card-content>
                    <table mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="transaction_id">
                            <th mat-header-cell *matHeaderCellDef>Transaction no.</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <span class="table-cell">{{element.transaction_id}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="transaction_type">
                            <th mat-header-cell *matHeaderCellDef>Transaction Type</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <span class="table-cell">{{element.transaction_type}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="print_datetime">
                            <th mat-header-cell *matHeaderCellDef>Print Datetime</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <span class="table-cell">{{element.print_datetime}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="print_month">
                            <th mat-header-cell *matHeaderCellDef>Print Month</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <span class="table-cell">{{getTimeFormat(element.print_month)}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef>Amount</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <span class="table-cell">{{element.amount | number:'1.0-2'}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="adjust_type">
                            <th mat-header-cell *matHeaderCellDef>Adjust Type</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <span class="table-cell">{{element.adjust_type}}</span> </td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="manage">
                            <th mat-header-cell *matHeaderCellDef>manage</th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <button mat-raised-button color="primary" (click)="onSent(element)">
                                    SENT
                                </button></td>
                        </ng-container> -->
                        <ng-container matColumnDef="noRecord">
                            <td mat-footer-cell *matFooterCellDef [attr.colspan]="dataColumns.length">No records found.
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="dataColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: dataColumns;"></tr>
                        <ng-template [ngIf]="true">
                            <tr mat-footer-row *matFooterRowDef="['noRecord']"
                                [ngClass]="{'hide' : dataSource.data.length > 0}"></tr>
                        </ng-template>
                    </table>
                    <mat-paginator class="pagination" [length]="dataSource.data.length" [pageSize]="10"
                        [pageSizeOptions]="['5', '10', '20', '30']" (page)="onPageChanged($event)">
                    </mat-paginator>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-card-content>
</app-layout>