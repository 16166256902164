<form #form="ngForm" (ngSubmit)="onCompleteDate()">

    <mat-label style="margin: auto 50px;">Print Date</mat-label>

    <mat-form-field floatLabel='never' appearance="outline">
        <input matInput [matDatepicker]="picker" ngModel name="date" readonly required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <button style="margin: auto 50px;" mat-raised-button color="primary" type="submit"
        [disabled]="form.invalid">ค้นหายอดรวม
    </button>


</form>