import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';
import { CancelService } from '../../service/cancel.service';

@Component({
  templateUrl: './cancel-page.component.html',
  styleUrls: ['./cancel-page.component.css']
})
export class CancelPageComponent implements OnInit, AfterViewInit {

  @ViewChild(NgForm, { static: false }) form: NgForm;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>();
  public dataColumns = ['transaction_id', 'transaction_type', 'print_datetime', 'print_month', 'amount', 'adjust_type'/*, 'manage'*/];

  constructor(private _cancelService: CancelService) { }

  ngOnInit(): void {
    this.getCancelData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  onPageChanged(event) {

  }

  async getCancelData() {
    this.dataSource.data = await this._cancelService.getcancelList();
  }

  getTimeFormat(data) {
    const date = new Date(data)
    const month = date.getMonth() + 1;
    return date.getFullYear() + '-' + month;
  }


  onSent(element){

  }


}
