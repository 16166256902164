import { Component, OnInit, OnChanges, AfterViewInit, ViewChild, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-table-feet-adjust',
  templateUrl: './table-feet-adjust.component.html',
  styleUrls: ['./table-feet-adjust.component.css']
})
export class TableFeetAdjustComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() dataModel;
  dataColumns = [
    'TransactionNo',
    'ItemNo',
    'DocType',
    'DocDate',
    'PostingKey',
    'Account',
    'Amount',
    'PmtTerm',
    'PmtMethod',
    'CustomerBranch',
    'WHTType1',
    'WHTCode1',
    'WHTBaseAmount',
    'Text'
  ];
  dataSource = new MatTableDataSource<any>();

  constructor() { }

  ngOnInit(): void {
    this.dataSource.data = this.dataModel;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges() {
    this.dataSource.data = this.dataModel;
    this.dataSource.paginator = this.paginator;
  }


  onPageChanged(event) {
    console.log(event);
  }

}
