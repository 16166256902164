import { Component, OnInit, Input, OnChanges, AfterViewInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-table-sap-adjust',
  templateUrl: './table-sap-adjust.component.html',
  styleUrls: ['./table-sap-adjust.component.css']
})
export class TableSapAdjustComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() dataModel;
  dataColumns = [
    'TransactionNo',
    'ItemNo',
    'DocType',
    'DocDate',
    'PostingKey',
    'Account',
    'PmtMethod',
    'text',
    'InvoiceRef',
    'FiscalYear',
    'LineItem'
  ]
  dataSource = new MatTableDataSource<any>();

  constructor() { }

  ngOnInit(): void {
    this.dataSource.data = this.dataModel;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges() {
    this.dataSource.data = this.dataModel;
    this.dataSource.paginator = this.paginator;
  }


  onPageChanged(event) {
    console.log(event);
  }

}
