<mat-card-content>
    <h3>ข้อมูลจาก SAP <span style="float: right;cursor:pointer" (click)="onCancel()">X</span></h3>
    <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div fxLayout="column" fxLayoutAlign="center stretch">

            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Business Partner</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="businessPartner" readonly>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Address Type</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="addressType" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Branch Code/Mail to</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="branchCode" readonly>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">

                    <label>Description</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="description" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Name 1 of Organization</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="name1OfOrganization" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Name 2 of Organization</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="name2OfOrganization" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Name 3 of Organization</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="name3OfOrganization" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Name 4 of Organization</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="name4OfOrganization" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Search Term 1</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="searchTerm1" readonly>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Search Term 2</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="searchTerm2" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Street1</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="street1" readonly>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Street2</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="street2" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Street3</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="street3">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Street4</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="street4" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Street5</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="street5" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>City</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="city" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>District</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="district" readonly>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">

                    <label>Postal code</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="postalcode" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Country Key</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="countryKey" readonly>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Language Key</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="languageKey" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Phone 1</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="phone1" readonly>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Extension 1</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="extension1" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Phone 2</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="phone2" readonly>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Extension 2</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="extension2" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none">
                <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                    <label>Fax Number</label>
                    <mat-form-field floatLabel='never' appearance="outline">
                        <input matInput type="text" formControlName="faxNumber" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                <label>Extension</label>
                <mat-form-field floatLabel='never' appearance="outline">
                    <input matInput type="text" formControlName="extension" readonly>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="none">
            <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                <label>Mobile Phone </label>
                <mat-form-field floatLabel='never' appearance="outline">
                    <input matInput type="text" formControlName="mobilePhone" readonly>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">
                <label>Email</label>
                <mat-form-field floatLabel='never' appearance="outline">
                    <input matInput type="text" formControlName="email" readonly>
                </mat-form-field>
            </div>
        </div>


        <h3>ข้อมูลจาก Limousine</h3>

        <div fxLayout="row" fxLayoutAlign="none">
            <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">

                <label>Company Code
                </label>
                <mat-form-field floatLabel='never' appearance="outline">
                    <select matNativeControl formControlName="companyCode" (change)="getCompanyName($event)">
                        <option *ngFor="let item of companyCodeList" [value]="item.comp_id">{{item.comp_code}}</option>
                    </select>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">

                <label>Company Name

                </label>
                <mat-form-field floatLabel='never' appearance="outline">
                    <input matInput type="text" formControlName="companyName" readonly>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="none">
            <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">

                <label>Client Code</label>
                <mat-form-field floatLabel='never' appearance="outline">
                    <select matNativeControl (change)="getClientName($event)" formControlName="clientCode">
                        <option *ngFor="let item of clientCodeList" [value]="item.client_id">{{item.client_code}}
                        </option>
                    </select>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="none" fxFlex="50%">

                <label>Client Name
                </label>
                <mat-form-field floatLabel='never' appearance="outline">
                    <input matInput type="text" formControlName="clientName">
                </mat-form-field>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 20px;">
            <button style="margin: auto 50px;" mat-stroked-button color="warn" type="button"
                (click)="onCancel()">ยกเลิก</button>
            <button style="margin: auto 50px;" mat-raised-button color="primary" type="submit">ตกลง</button>
        </div>
    </form>
</mat-card-content>