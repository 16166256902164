import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';  
import {  MessageService } from '../../services/message.service';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnDestroy {
  @ViewChild('sidenav') sidenav: MatSidenav;
  public isShowSidebar: boolean;
  public mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  subscription: Subscription; 
  public isShowMenu: any;
  public innerWidth: any;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,private message_service: MessageService) {
    this.mobileQuery = media.matchMedia('(max-width: 1024px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);

    this.isShowSidebar = !this.mobileQuery.matches;

    this.subscription = this.message_service.getText().subscribe(message => {  
      this.isShowMenu = message.text; 
      this.isShowSidebar = false;
    });

    this.innerWidth = window.innerWidth;
    
  }

  public ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
    this.sidenav.close();
  }
}
