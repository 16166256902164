export const REPORT = [{ reportID: 1, reportName: '01 LIM Cash Bank transfer ไม่มีภาษีขาย ชำระเงินหน้าเคาน์เตอร์' },
{ reportID: 2, reportName: '02 LIM Credit Card, Debit Card ไม่มีภาษีขาย ชำระเงินหน้าเคาน์เตอร์' },
{ reportID: 3, reportName: '03 LIM Credit Card,-Amex  ไม่มีภาษีขาย ชำระเงินหน้าเคาน์เตอร์' },
{ reportID: 6, reportName: '06 LIM Cash Bank transfer ไม่มีภาษีขาย จองผ่าน Call Center' },
{ reportID: 7, reportName: '07 LIM Cash Bank transfer กรณียกเลิกจองผ่าน Call Center' },
{ reportID: 8, reportName: '08 LIM Credit Card, Debit Card ไม่มีภาษีขาย จองผ่านเว็บไซต์' },
{ reportID: 9, reportName: '09 LIM Credit Card, Debit Card กรณียกเลิกจองผ่านเว็บไซต์' },
{ reportID: 4, reportName: '04 LIM Adjust - (ลดจำนวนเงินที่มีการส่งข้อมูลมาเเล้ว)' },
{ reportID: 5, reportName: '05 LIM Adjust + (เพิ่มจำนวนเงินที่มีการส่งข้อมูลมาเเล้ว)' },
{ reportID: 10, reportName: '10 LIM Fleet invoice' },
{ reportID: 11, reportName: '11 เพิ่มหนี้' },
{ reportID: 12, reportName: '12 ลดหนี้' }
];
