import { Component, OnInit, Input, OnChanges, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { RetailSearch } from '../../model/retailSearch';

@Component({
  selector: 'app-table-sap-waiting',
  templateUrl: './table-sap-waiting.component.html',
  styleUrls: ['./table-sap-waiting.component.css']
})
export class TableSapWaitingComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() dataModel;
  dataSource = new MatTableDataSource<RetailSearch[]>();
  dataColumns = ['TransactionNo', 'ItemNo', 'DocType', 'DocDate', 'PostingKey', 'Amount', 'Account', 'PmtMethod', 'text'];
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges() {
    this.dataSource.data = this.dataModel;
    this.dataSource.paginator = this.paginator;
  }

  onPageChanged(event) {
    console.log(event);
  }

}
