import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { storage } from 'src/app/consts/storage';
import { RetailSearch } from '../model/retailSearch';
import { AuthService } from '../../auth/services';
import { routes } from 'src/app/consts';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  public storage: typeof storage = storage;
  public routers: typeof routes = routes;

  constructor(
    private _http: HttpClient,
    private userService: AuthService,
    private router: Router
  ) { }

  async getRetailSearch(reportID, date, type = null): Promise<RetailSearch[]> {
    return new Promise(async (reslove, reject) => {
      let data = { PrintDatetime: date };
      data = type ? Object.assign({ Type: type }, data) : data;
      try {
        reportID = ('0' + reportID).slice(-2);
        let headers = new HttpHeaders();
        const token = localStorage.getItem(storage.TOKEN);
        headers = headers.set('token', token);
        let response = await this._http.post<any>(`${environment.apiUrl}/retail/${reportID}/search`, data, { headers }).toPromise();
        // if (reportID === '04') {
          response.data = response.data.reduce((acc, cur) => { return acc.concat(cur); }, []);
        // }
        reslove(response.data);
      } catch (e) {
        reject(this.getErrorMessage(e));
      }
    });

  }

  async getRetailLog(reportID, date, type = null): Promise<any[]> {
    return new Promise(async (reslove, reject) => {
      try {
        let headers = new HttpHeaders();
        reportID = ('0' + reportID).slice(-2);
        let data = { 'PrintDatetime': date };
        data = type ? Object.assign({ Type: type }, data) : data;
        headers = headers.set('token', localStorage.getItem(storage.TOKEN));
        const response = await this._http.post<any>(`${environment.apiUrl}/retail/${reportID}/log`, data, { headers }).toPromise();
        const dataReturn = response.data.reduce((acc, cur) => { return acc.concat(cur); }, []);
        reslove(dataReturn);
      } catch (e) {
        reject(this.getErrorMessage(e));
      }
    });
  }

  async callSapRetail(reportID, date, type = null) {
    return new Promise(async (reslove, reject) => {
      try {
        let data = { 'PrintDatetime': date };
        data = type ? Object.assign({ Type: type }, data) : data;
        let headers = new HttpHeaders();
        reportID = ('0' + reportID).slice(-2);
        headers = headers.set('token', localStorage.getItem(storage.TOKEN));
        const response = await this._http.post<any>(`${environment.apiUrl}/retail/${reportID}/sap`, data, { headers }).toPromise();
        reslove(response.data);
      } catch (e) {
        reject(this.getErrorMessage(e));

      }
    });
  }


  async getCallCenterSearch(reportID, date, type = null): Promise<any[]> {

    return new Promise(async (reslove, reject) => {
      try {
        let data = { 'PrintDatetime': date };
        data = type ? Object.assign({ Type: type }, data) : data;
        let headers = new HttpHeaders();
        reportID = ('0' + reportID).slice(-2);
        headers = headers.set('token', localStorage.getItem(storage.TOKEN));
        const response = await this._http.post<any>(`${environment.apiUrl}/callcenter/${reportID}/search`, data, { headers: headers }).toPromise();
        response.data = response.data.reduce((acc, cur) => { return acc.concat(cur); }, []);
        reslove(response.data);
      } catch (e) {
        reject(this.getErrorMessage(e));

      }
    });
  }

  async getCallCenterLog(reportID, date, type = null): Promise<any[]> {
    return new Promise(async (reslove, reject) => {
      try {
        reportID = ('0' + reportID).slice(-2);
        let data = { 'PrintDatetime': date };
        data = type ? Object.assign({ Type: type }, data) : data;
        let headers = new HttpHeaders();
        headers = headers.set('token', localStorage.getItem(storage.TOKEN));
        const response = await this._http.post<any>(`${environment.apiUrl}/callcenter/${reportID}/log`, data, { headers: headers }).toPromise();
        const dataReturn = response.data.reduce((acc, cur) => { return acc.concat(cur); }, []);
        reslove(dataReturn);
      } catch (e) {
        reject(this.getErrorMessage(e));

      }
    });
  }

  async callSapCallCenter(reportID, date, type = null) {
    return new Promise(async (reslove, reject) => {
      try {
        let headers = new HttpHeaders();
        reportID = ('0' + reportID).slice(-2);
        let data = { 'PrintDatetime': date };
        data = type ? Object.assign({ Type: type }, data) : data;
        headers = headers.set('token', localStorage.getItem(storage.TOKEN));
        const response = await this._http.post<any>(`${environment.apiUrl}/callcenter/${reportID}/sap`, data, { headers }).toPromise();
        reslove(response.data);
      } catch (e) {
        reject(this.getErrorMessage(e));

      }
    });
  }

  async getWebsiteSearch(reportID, date, type = null): Promise<any[]> {
    return new Promise(async (reslove, reject) => {
      reportID = ('0' + reportID).slice(-2);
      let data = { 'PrintDatetime': date };
      data = type ? Object.assign({ Type: type }, data) : data;
      try {
        let headers = new HttpHeaders();
        headers = headers.set('token', localStorage.getItem(storage.TOKEN));
        const response = await this._http.post<any>(`${environment.apiUrl}/website/${reportID}/search`, data, { headers: headers }).toPromise();
        response.data = response.data.reduce((acc, cur) => { return acc.concat(cur); }, []);
        reslove(response.data);
      } catch (e) {
        reject(this.getErrorMessage(e));

      }
    });
  }


  async getWebsiteLog(reportID, date, type = null): Promise<any[]> {
    return new Promise(async (reslove, reject) => {
      reportID = ('0' + reportID).slice(-2);
      let data = { 'PrintDatetime': date };
      data = type ? Object.assign({ Type: type }, data) : data;
      try {
        let headers = new HttpHeaders();
        headers = headers.set('token', localStorage.getItem(storage.TOKEN));
        const response = await this._http.post<any>(`${environment.apiUrl}/website/${reportID}/log`, data, { headers: headers }).toPromise();
        const dataReturn = response.data.reduce((acc, cur) => { return acc.concat(cur); }, []);
        reslove(dataReturn);
      } catch (e) {
        reject(this.getErrorMessage(e));

      }
    });
  }

  async callSapWebsite(reportID, date, type = null) {
    return new Promise(async (reslove, reject) => {
      try {
        let data = { 'PrintDatetime': date };
        data = type ? Object.assign({ Type: type }, data) : data;
        let headers = new HttpHeaders();
        reportID = ('0' + reportID).slice(-2);
        headers = headers.set('token', localStorage.getItem(storage.TOKEN));
        const response = await this._http.post<any>(`${environment.apiUrl}/website/${reportID}/sap`, data, { headers }).toPromise();
        reslove(response.data);
      } catch (e) {
        reject(this.getErrorMessage(e));

      }
    });
  }

  async getFeetSearch(reportID, client_id, year, month): Promise<any[]> {
    return new Promise(async (reslove, reject) => {
      reportID = ('0' + reportID).slice(-2);
      const data = {
        month, year, client_id
      };
      try {
        let headers = new HttpHeaders();
        headers = headers.set('token', localStorage.getItem(storage.TOKEN));
        const response = await this._http.post<any>(`${environment.apiUrl}/fleet/${reportID}/search`, data, { headers: headers }).toPromise();
        const dataReturn = response.data.reduce((acc, cur) => { return acc.concat(cur); }, []);
        reslove(dataReturn);
      } catch (e) {
        reject(this.getErrorMessage(e));

      }
    });
  }


  async getFeetLog(reportID, client_id, year, month): Promise<any[]> {
    return new Promise(async (reslove, reject) => {
      reportID = ('0' + reportID).slice(-2);
      const data = {
        month, year, client_id
      };
      try {
        let headers = new HttpHeaders();
        headers = headers.set('token', localStorage.getItem(storage.TOKEN));
        const response = await this._http.post<any>(`${environment.apiUrl}/fleet/${reportID}/log`, data, { headers: headers }).toPromise();
        const dataReturn = response.data.reduce((acc, cur) => { return acc.concat(cur); }, []);
        reslove(dataReturn);
      } catch (e) {
        reject(this.getErrorMessage(e));

      }
    });
  }

  async callSapFeet(reportID, client_id, year, month) {
    return new Promise(async (reslove, reject) => {
      try {
        const data = {
          month, year, client_id
        };
        let headers = new HttpHeaders();
        reportID = ('0' + reportID).slice(-2);
        headers = headers.set('token', localStorage.getItem(storage.TOKEN));
        const response = await this._http.post<any>(`${environment.apiUrl}/fleet/${reportID}/sap`, data, { headers }).toPromise();
        reslove(response.data);
      } catch (e) {
        reject(this.getErrorMessage(e));

      }
    });
  }

  getClientCode() {
    return new Promise(async (reslove, reject) => {
      try {
        const response = await this._http.get<any>(`${environment.apiUrl}/common/fleet/sap/matched`).toPromise();
        reslove(response.data);
      } catch (e) {
        reject(this.getErrorMessage(e));
      }
    });
  }

  getErrorMessage(e) {
    console.log(e);
    let message = null;
    if (e.status === 401) {
      message = `Token expired กรุณา login ใหม่`;
      this.userService.signOut();
      this.router.navigate([this.routers.LOGIN]);
    } else {
      message = e.error.message;
    }
    return message;
  }
}
