import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { GuardGuard } from './shared/guard/guard.guard';

const routes: Routes = [
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  { path: 'report', loadChildren: () => import('./pages/report/report.module').then(m => m.ReportModule), canActivate: [GuardGuard] },
  { path: 'manage', loadChildren: () => import('./pages/manage/manage.module').then(m => m.ManageModule), canActivate: [GuardGuard] },
  { path: 'cancel', loadChildren: () => import('./pages/cancel/cancel.module').then(m => m.CancelModule), canActivate: [GuardGuard] },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
