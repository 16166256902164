import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { REPORT } from '../../const/report';
import { ToastrService } from 'ngx-toastr';
import { ErrorToastrComponent } from 'src/app/shared/component/error-toastr/error-toastr.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { LoadingComponent } from 'src/app/shared/component/loading/loading.component';
import { RetailSearch } from '../../model/retailSearch';
import { RetailLog } from '../../model/retailLog';
import { DateSelectionComponent } from '../../component/date-selection/date-selection.component';
import { ReportService } from '../../service/report.service';
import {  MessageService } from '../../../../services/message.service';
@Component({
  templateUrl: './report01.component.html',
  styleUrls: ['./report01.component.scss']
})
export class Report01Component implements OnInit {
  isPage:any = 'report'; 
  public reportName: string;
  public reportID: string;
  public dataModel: RetailSearch[] = [];
  public dataModel2: RetailLog[] = [];
  public selectedDate = '';
  @ViewChild(DateSelectionComponent, { static: false }) datesSelecter: DateSelectionComponent;

  constructor(
    private route: ActivatedRoute,
    private reportService: ReportService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private message_service: MessageService
  ) { }

  async ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.reportName = REPORT.find(o => o.reportID == params.reportID).reportName;
      this.reportID = params.reportID;
    });
    this.message_service.sendText(this.isPage); 
  }


  async onDateSelected(event) {
    this.selectedDate = event;
    let config = new MatDialogConfig();
    config = {
      panelClass: 'tranparent-modal',
      disableClose: true
    };
    let dialogRef = this.dialog.open(LoadingComponent, config);

    try {
      this.dataModel = await this.reportService.getRetailSearch(this.reportID, event);
      this.dataModel2 = await this.reportService.getRetailLog(this.reportID, event);
    } catch (e) {
      this.toastr.show(
        e,
        null,
        {
          positionClass: 'toast-top-right',
          toastComponent: ErrorToastrComponent,
          timeOut: 3000,
          tapToDismiss: false
        }
      );
    } finally {
      dialogRef.close();
    }
  }

  async onCallSap() {

    let config = new MatDialogConfig();
    config = {
      panelClass: 'tranparent-modal',
      disableClose: true
    };
    let dialogRef = this.dialog.open(LoadingComponent, config);

    try {
      await this.reportService.callSapRetail(this.reportID, this.selectedDate);
      this.dataModel2 = await this.reportService.getRetailLog(this.reportID, this.selectedDate);
      this.dataModel = [];
      this.selectedDate = '';
    } catch (e) {
      this.toastr.show(
        e,
        null,
        {
          positionClass: 'toast-top-right',
          toastComponent: ErrorToastrComponent,
          timeOut: 3000,
          tapToDismiss: false
        }
      );
    } finally {
      dialogRef.close();
    }
  }

}
