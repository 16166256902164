<table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="TransactionNo">
        <th mat-header-cell *matHeaderCellDef>Transaction no.</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.TransactionNo}}</span> </td>
    </ng-container>
    <ng-container matColumnDef="ItemNo">
        <th mat-header-cell *matHeaderCellDef>Item no.</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.ItemNo}}</span> </td>
    </ng-container>
    <ng-container matColumnDef="DocType">
        <th mat-header-cell *matHeaderCellDef>Doc Type</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.DocType}}</span> </td>
    </ng-container>
    <ng-container matColumnDef="DocDate">
        <th mat-header-cell *matHeaderCellDef>Doc Date</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.DocDate}}</span> </td>
    </ng-container>
    <ng-container matColumnDef="PostingKey">
        <th mat-header-cell *matHeaderCellDef>Posting Key</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.PostingKey}}</span> </td>
    </ng-container>
    <ng-container matColumnDef="Account">
        <th mat-header-cell *matHeaderCellDef>Account</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.Account}}</span> </td>
    </ng-container>
    <ng-container matColumnDef="Amount">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.Amount | number:'1.0-2'}}</span> </td>
    </ng-container>
    <ng-container matColumnDef="PmtTerm">
        <th mat-header-cell *matHeaderCellDef>Payment Term</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.PmtTerm}}</span> </td>
    </ng-container>
    <ng-container matColumnDef="PmtMethod">
        <th mat-header-cell *matHeaderCellDef>Pmt Method</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.PmtMethod}}</span> </td>
    </ng-container>
    <ng-container matColumnDef="CustomerBranch">
        <th mat-header-cell *matHeaderCellDef>Customer Branch</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.CustomerBranch}}</span> </td>
    </ng-container>
    <ng-container matColumnDef="WHTType1">
        <th mat-header-cell *matHeaderCellDef>WHT Type1</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.WHTType1}}</span> </td>
    </ng-container>
    <ng-container matColumnDef="WHTCode1">
        <th mat-header-cell *matHeaderCellDef>WHT Code1</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.WHTCode1}}</span> </td>
    </ng-container>
    <ng-container matColumnDef="WHTBaseAmount">
        <th mat-header-cell *matHeaderCellDef>WHT Base Amount1</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.WHTBaseAmount}}</span> </td>
    </ng-container>
    <ng-container matColumnDef="Text">
        <th mat-header-cell *matHeaderCellDef>text</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.Text}}</span> </td>
    </ng-container>    
    <ng-container matColumnDef="PostingDate">
        <th mat-header-cell *matHeaderCellDef>Posting Date</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.PostingDate}}</span> </td>
    </ng-container>
    <ng-container matColumnDef="InvoiceRef">
        <th mat-header-cell *matHeaderCellDef>Invoice Ref.</th>
        <td mat-cell *matCellDef="let element; index as i;">
            <span class="table-cell">{{element.InvoiceRef}}</span> </td>
    </ng-container>
    <ng-container matColumnDef="noRecord">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="dataColumns.length">No records found.</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="dataColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: dataColumns;"></tr>
    <ng-template [ngIf]="true">
        <tr mat-footer-row *matFooterRowDef="['noRecord']" [ngClass]="{'hide' : dataSource.data.length > 0}"></tr>
    </ng-template>
</table>
<mat-paginator class="pagination" [length]="dataSource.data.length" [pageSize]="10"
    [pageSizeOptions]="['5', '10', '20', '30']" (page)="onPageChanged($event)">
</mat-paginator>