import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MangePageComponent } from './container/mange-page/mange-page.component';


const routes: Routes = [{
  path: '',
  component: MangePageComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageRoutingModule { }
