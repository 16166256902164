import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { NgForm, FormBuilder, FormGroup } from '@angular/forms';
import { LoadingComponent } from 'src/app/shared/component/loading/loading.component';
import { ErrorToastrComponent } from 'src/app/shared/component/error-toastr/error-toastr.component';
import { ToastrService } from 'ngx-toastr';
import { ManageService } from '../../service/manage.service';

@Component({
  selector: 'app-edit-detail',
  templateUrl: './edit-detail.component.html',
  styleUrls: ['./edit-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDetailComponent implements OnInit, AfterViewInit {

  // @ViewChild(NgForm, { static: false }) form: NgForm;
  public form: FormGroup;
  public companyCodeList = [];
  public clientCodeList = [];
  constructor(
    private dialogRef: MatDialogRef<EditDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private _manageService: ManageService
  ) { }

  async ngOnInit() {
    let config = new MatDialogConfig();
    config = {
      panelClass: 'tranparent-modal',
      disableClose: true
    };
    let dialogRef = this.dialog.open(LoadingComponent, config);
    try {

      this.companyCodeList = this.data.companyCodeList;
      this.clientCodeList = this.data.clientCodeList;

      this.form = this.fb.group({
        businessPartner: [this.data.PARTNER],
        branchCode: [this.data.J_1TPBUPL],
        description: [this.data.DESCRIPTION],
        searchTerm2: [this.data.BU_SORT2],
        addressType: [this.data.ADR_KIND],
        name1OfOrganization: [this.data.NAME_ORG1],
        name2OfOrganization: [this.data.NAME_ORG2],
        name3OfOrganization: [this.data.NAME_ORG3],
        name4OfOrganization: [this.data.NAME_ORG4],
        searchTerm1: [this.data.BU_SORT1],
        street1: [this.data.STREET],
        street2: [this.data.STR_SUPPL1],
        street3: [this.data.STR_SUPPL2],
        street4: [this.data.STR_SUPPL3],
        street5: [this.data.LOCATION],
        city: [this.data.CITY1],
        district: [this.data.CITY2],
        postalcode: [this.data.POST_CODE1],
        countryKey: [this.data.COUNTRY],
        languageKey: [this.data.LANGU],
        phone1: [this.data.TEL_NUMBER],
        extension1: [this.data.TEL_EXTENS],
        phone2: [this.data.TEL_NUMBER2],
        extension2: [this.data.TEL_EXTENS2],
        faxNumber: [this.data.FAX_NUMBER],
        extension: [this.data.FAX_EXTENS],
        mobilePhone: [this.data.MOB_NUMBER],
        email: [this.data.SMTP_ADDR],
        companyCode: [this.getCompanyId(this.data.companyCodeList, this.data.comp_code)],
        companyName: [this.data.comp_name],
        clientCode: [this.getClientId(this.data.clientCodeList, this.data.client_code)],
        clientName: [this.data.comp_name],
      });
    } catch (e) {
      this.toastr.show(
        e,
        null,
        {
          positionClass: 'toast-top-right',
          toastComponent: ErrorToastrComponent,
          timeOut: 3000,
          tapToDismiss: false
        }
      );
    } finally {
      dialogRef.close();
    }
  }




  async getCompanyName(event) {
    let config = new MatDialogConfig();
    config = {
      panelClass: 'tranparent-modal',
      disableClose: true
    };
    let dialogRef = this.dialog.open(LoadingComponent, config);
    try {
      const name = this.companyCodeList.find(o => o.comp_id == event.target.value);
      this.form.get('companyName').setValue(name.name);
      this.clientCodeList = [];
      this.clientCodeList = await this._manageService.getSapcustomer(event.target.value);
      //console.log(this.clientCodeList);
      this.clientCodeList.sort((a,b) => (a.client_code > b.client_code) ? 1 : ((b.client_code > a.client_code) ? -1 : 0));

    } catch (e) {
      this.toastr.show(
        e,
        null,
        {
          positionClass: 'toast-top-right',
          toastComponent: ErrorToastrComponent,
          timeOut: 3000,
          tapToDismiss: false
        }
      );
    } finally {
      dialogRef.close();
    }
  }

  async getClientName(event) {
    const name = this.clientCodeList.find(o => o.client_id == event.target.value);
    this.form.get('clientName').setValue(name.name);
  }

  ngAfterViewInit(): void {

  }


  onCancel() {
    this.dialogRef.close();
  }

  async onSubmit() {
    let config = new MatDialogConfig();
    config = {
      panelClass: 'tranparent-modal',
      disableClose: true
    };
    let dialogRef = this.dialog.open(LoadingComponent, config);

    try {
      const response = await this._manageService.update(this.data.id, this.form.get('clientCode').value, this.form.get('companyCode').value);
      let data = this.form.getRawValue();
      data.clientId = this.form.get('clientCode').value;
      data.clientCode = this.getClientCode(this.clientCodeList, this.form.get('clientCode').value);
      data.companyId = this.form.get('companyCode').value;
      data.companyCode = this.getCompanyCode(this.companyCodeList, data.companyId);

      this.dialogRef.close(data);
    } catch (e) {
      this.toastr.show(
        e,
        null,
        {
          positionClass: 'toast-top-right',
          toastComponent: ErrorToastrComponent,
          timeOut: 3000,
          tapToDismiss: false
        }
      );
    } finally {
      dialogRef.close();
    }
  }


  getCompanyCode(totalElement, code) {
    const response = totalElement.find(e => e.comp_id == code);
    return response ? response.comp_code : '';
  }
  getClientCode(totalElement, code) {
    const response = totalElement.find(e => e.client_id == code);
    return response ? response.client_code : '';
  }

  getCompanyId(totalElement, code) {
    const response = totalElement.find(e => e.comp_code == code);
    return response ? response.comp_id : '';
  }
  getClientId(totalElement, code) {
    const response = totalElement.find(e => e.client_code == code);
    return response ? response.client_id : '';
  }
}
