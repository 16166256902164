<form class="form" [formGroup]="form" (ngSubmit)="login()">
  <mat-form-field floatLabel='never' class="form__input">
    <input matInput placeholder="Username" type="text" formControlName="username">
  </mat-form-field>

  <mat-form-field floatLabel='never' class="form__input">
    <input matInput placeholder="Password" type="password" formControlName="password">
  </mat-form-field>

  <div class="form-actions">
    <button *ngIf="!isLogining" class="form-actions__login" mat-raised-button color="primary"
      type="submit">Login</button>
    <img *ngIf="isLogining" src="./assets/loading.gif" style="height: 30px;" />
  </div>
</form>