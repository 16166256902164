<app-layout>
    <mat-toolbar class="page-header" role="heading">
        <h1>{{reportName}}</h1>
    </mat-toolbar>

    <mat-card-content>
        <div>
            <mat-card>
                <form #form="ngForm" (ngSubmit)="onDateSelected()">

                    <div fxLayout="row" fxLayoutAlign="none">
                        <mat-label style="margin: auto 50px; width: 75px;">Report type</mat-label>
                        <mat-form-field appearance="outline" style="width: 236px;">
                            <select matNativeControl required ngModel name="reportType"
                                style="border: none; width: 236px;">
                                <option value="01">Cash</option>
                                <option value="02">Credit</option>
                                <option value="03">Amex</option>
                            </select>
                        </mat-form-field>
                    </div>




                    <div fxLayout="row" fxLayoutAlign="none">
                        <mat-label style="margin: auto 50px; width: 75px;">Print Date</mat-label>

                        <mat-form-field floatLabel='never' appearance="outline">
                            <input matInput [matDatepicker]="picker" ngModel name="date" readonly required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="none">
                        <mat-label style="margin: auto 50px; width: 75px;"></mat-label>
                        <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">ค้นหายอดรวม
                        </button>
                    </div>
                </form>
            </mat-card>
        </div>

        <div>
            <mat-card>
                <mat-card-title>
                    รายการเคยส่ง SAP
                </mat-card-title>
                <mat-card-content>
                    <app-table-sap-sent [dataModel]="dataModel"></app-table-sap-sent>
                </mat-card-content>
            </mat-card>
        </div>


        <div>
            <mat-card>
                <mat-card-title>
                    <div fxLayout="row">
                        <div fxLayout="row" fxFlex="90%" fxLayoutAlign="start none">
                            รายการที่มี Adjust
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end none">
                            <button mat-raised-button color="primary" (click)="onCallSap()" [disabled]="dataModel2.length <= 0">
                                ส่งข้อมูลให้ SAP
                            </button>
                        </div>
                    </div>
                </mat-card-title>
                <mat-card-content>
                    <app-table-sap-waiting [dataModel]="dataModel2"></app-table-sap-waiting>
                </mat-card-content>
            </mat-card>
        </div>

        <div>
            <mat-card>
                <mat-card-title>
                    <div fxLayout="row">
                        <div fxLayout="row" fxFlex="90%" fxLayoutAlign="start none">
                            Log Adjust ที่ส่ง SAP แล้ว
                        </div>
                    </div>
                </mat-card-title>
                <mat-card-content>
                    <app-table-sap-sent [dataModel]="dataModel3"></app-table-sap-sent>
                </mat-card-content>
            </mat-card>
        </div>

    </mat-card-content>

</app-layout>