import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportRoutingModule } from './report-routing.module';
import { ReportMainComponent } from './containers/report-main/report-main.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FormsModule } from '@angular/forms';
import { Report01Component } from './containers/report01/report01.component';
import { Report04Component } from './containers/report04/report04.component';
import { Report10Component } from './containers/report10/report10.component';
import { Report11Component } from './containers/report11/report11.component';
import { DateSelectionComponent } from './component/date-selection/date-selection.component';
import { TableSapAdjustComponent } from './component/table-sap-adjust/table-sap-adjust.component';
import { TableSapAdjustSentComponent } from './component/table-sap-adjust-sent/table-sap-adjust-sent.component';
import { TableSapSentComponent } from './component/table-sap-sent/table-sap-sent.component';
import { TableSapWaitingComponent } from './component/table-sap-waiting/table-sap-waiting.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { Report06Component } from './containers/report06/report06.component';
import { Report08Component } from './containers/report08/report08.component';
import { TableFeetComponent } from './component/table-feet/table-feet.component';
import { TableFeetSentComponent } from './component/table-feet-sent/table-feet-sent.component';
import { TableFeetAdjustComponent } from './component/table-feet-adjust/table-feet-adjust.component';

@NgModule({
  declarations: [
    ReportMainComponent,
    Report01Component,
    Report04Component,
    Report10Component,
    Report11Component,
    DateSelectionComponent,
    TableSapAdjustComponent,
    TableSapAdjustSentComponent,
    TableSapSentComponent,
    TableSapWaitingComponent,
    Report06Component,
    Report08Component,
    TableFeetComponent,
    TableFeetSentComponent,
    TableFeetAdjustComponent
  ],
  imports: [
    ReportRoutingModule,
    CommonModule,
    MatTableModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatGridListModule,
    MatSelectModule,
    MatInputModule,
    NgApexchartsModule,
    FormsModule,
    SharedModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatButtonModule
  ]
})
export class ReportModule { }
