<div class="toastr-wrapper">
  <div class="toastr-content">
    <div class="toastr-wrapper-icon">
      <mat-icon class="toastr-icon">announcement</mat-icon>
    </div>
    <p class="toastr-content__title">New user feedback received</p>
  </div>
  <div class="toastr-wrapper-icon">
    <mat-icon class="toastr-icon" (click)="remove()">close</mat-icon>
  </div>
</div>
