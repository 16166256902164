import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { routes } from 'src/app/consts';
import { AuthService } from '../../auth/services';

@Injectable({
  providedIn: 'root'
})
export class ManageService {

  public routers: typeof routes = routes;

  constructor(
    private _http: HttpClient,
    private userService: AuthService,
    private router: Router
  ) { }

  async getClient(): Promise<Array<any>> {
    return new Promise(async (reslove, reject) => {

      try {
        const response = await this._http.get<any>(`${environment.apiUrl}/common/fleet/company`).toPromise();
        reslove(response.data);
      } catch (e) {
         reject(this.getErrorMessage(e));

      }
    });

  }


  async getSapcustomer(id): Promise<Array<any>> {
    return new Promise(async (reslove, reject) => {
      try {
        let params = new HttpParams().set('comp_id', id);
        const response = await this._http.get<any>(`${environment.apiUrl}/common/fleet/client`, { params: params }).toPromise();
        reslove(response.data);
      } catch (e) {
         reject(this.getErrorMessage(e));
      }
    });
  }

  async search(limit, page, data): Promise<any> {
    return new Promise(async (reslove, reject) => {
      try {
        let params = new HttpParams().set('limit', limit);
        params = params.append('page', page);
        // params = params.append('keyword', keyword);
        const response = await this._http.post<any>(`${environment.apiUrl}/common/fleet/sap`, data, { params: params }).toPromise();
        reslove(response);
      } catch (e) {
         reject(this.getErrorMessage(e));
      }
    });
  }

  async update(id, client_id, comp_id): Promise<Array<any>> {
    return new Promise(async (reslove, reject) => {
      try {
        const data = {
          client_id,
          comp_id
        };
        const response = await this._http.patch<any>(`${environment.apiUrl}/common/fleet/${id}`, data, { observe: 'response' }).toPromise();
        reslove(response.body.data);
      } catch (e) {
         reject(this.getErrorMessage(e));
      }
    });
  }

  getErrorMessage(e) {
    console.log(e)
    let message = null;
    if (e.status === 401) {
      message = `Token expired กรุณา login ใหม่`;
      this.userService.signOut();
      this.router.navigate([this.routers.LOGIN]);
    } else {
      message = e.error.message;
    }
    return message;
  }
}
