import { Component, OnInit } from '@angular/core';
import { routes } from '../../consts/routes';
import { storage } from 'src/app/consts/storage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public routes: typeof routes = routes;
  public isOpenUiElements = false;
  public user: any = {};

  constructor(private router: Router){}

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem(storage.USER));
  }

  public openUiElements() {
    this.isOpenUiElements = !this.isOpenUiElements;
  }
  clearNoti(){
    this.user.isCancel = false;
    localStorage.setItem(storage.USER, JSON.stringify(this.user));
    this.router.navigate([this.routes.CANCEL]);
  }
}
