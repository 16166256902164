import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  templateUrl: './report-main.component.html',
  styleUrls: ['./report-main.component.css']
})
export class ReportMainComponent implements OnInit {

  public menuList = [
    {
      parent: 'Retail',
      children: [
        { name: '01 LIM Cash Bank transfer ไม่มีภาษีขาย ชำระเงินหน้าเคาน์เตอร์', path: '/report/report-01', param: { reportID: 1 } },
        { name: '02 LIM Credit Card, Debit Card ไม่มีภาษีขาย ชำระเงินหน้าเคาน์เตอร์', path: '/report/report-01', param: { reportID: 2 } },
        { name: '03 LIM Credit Card,-Amex  ไม่มีภาษีขาย ชำระเงินหน้าเคาน์เตอร์', path: '/report/report-01', param: { reportID: 3 } }
      ]
    },
    {
      parent: 'Call Center',
      children: [
        { name: '06 LIM Cash Bank transfer ไม่มีภาษีขาย จองผ่าน Call Center', path: '/report/report-06', param: { reportID: 6 } },
        { name: '07 LIM Cash Bank transfer กรณียกเลิกจองผ่าน Call Center', path: '/report/report-06', param: { reportID: 7 } }
      ]
    },
    {
      parent: 'Website',
      children: [
        { name: '08 LIM Credit Card, Debit Card ไม่มีภาษีขาย จองผ่านเว็บไซต์', path: '/report/report-08', param: { reportID: 8 } },
        { name: '09 LIM Credit Card, Debit Card กรณียกเลิกจองผ่านเว็บไซต์', path: '/report/report-08', param: { reportID: 9 } }
      ]
    },
    {
      parent: 'Adjust Retail',
      children: [
        { name: '04 LIM Adjust - (ลดจำนวนเงินที่มีการส่งข้อมูลมาเเล้ว)', path: '/report/report-04', param: { reportID: 4 } },
        { name: '05 LIM Adjust + (เพิ่มจำนวนเงินที่มีการส่งข้อมูลมาเเล้ว)', path: '/report/report-04', param: { reportID: 5 } }
      ]
    },
    {
      parent: 'Fleet',
      children: [
        { name: '10 LIM Fleet invoice', path: '/report/report-10', param: { reportID: 10, reportName: '10 LIM Fleet invoice' } },
        // { name: '11 เพิ่มหนี้', path: '/report/report-11', param: { reportID: 11, reportName: '11 เพิ่มหนี้' } },
        { name: '12 ลดหนี้', path: '/report/report-11', param: { reportID: 12, reportName: '12 ลดหนี้' } },
      ]
    }
  ];

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  navigateTo(data) {
    this.router.navigate([data.path], { queryParams: data.param });
  }

}
