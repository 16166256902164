<div class="auth-page">
  <div class="auth-page__logo">
    <div class="auth-page__logo-wrapper">
      <img class="auth-page__logo-img" src="./assets/aot-limo-logo2.png" alt="logo" style="background: white;
      padding: 10px;
      border-radius: 10px;">
      <!-- <h6 class="auth-page__logo-title">SAP AOT</h6> -->
    </div>
  </div>
  <div class="auth-page__content-block">
    <div class="auth-page__content-wrapper">
      <mat-tab-group class="auth-page__group">
        <mat-tab label="Login">
          <app-login-form (sendLoginForm)="sendLoginForm()"></app-login-form>
        </mat-tab>       
      </mat-tab-group>
    </div>
  </div>
</div>