import { Component, OnInit, ChangeDetectionStrategy, OnChanges, AfterViewInit, ViewChild, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-table-sap-adjust-sent',
  templateUrl: './table-sap-adjust-sent.component.html',
  styleUrls: ['./table-sap-adjust-sent.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableSapAdjustSentComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() dataModel;
  dataColumns = [
    'TransactionNo',
    'ItemNo',
    'DocType',
    'DocDate',
    'PostingKey',
    'Account',
    'Amount',
    'PmtMethod',
    'text',
    'InvoiceRef',
    'FiscalYear',
    'LineItem',
    'PostingDate'    
  ]
  dataSource = new MatTableDataSource<any>();

  constructor() { }

  ngOnInit(): void {
    this.dataSource.data = this.dataModel;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges() {
    this.dataSource.data = this.dataModel;
    this.dataSource.paginator = this.paginator;
  }


  onPageChanged(event) {
    console.log(event);
  }

}
