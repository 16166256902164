import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { REPORT } from '../../const/report';
import { ErrorToastrComponent } from 'src/app/shared/component/error-toastr/error-toastr.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoadingComponent } from 'src/app/shared/component/loading/loading.component';
import { ReportService } from '../../service/report.service';
import { NgForm } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import {  MessageService } from '../../../../services/message.service';

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY"
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  templateUrl: './report04.component.html',
  styleUrls: ['./report04.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class Report04Component implements OnInit {
  isPage:any = 'report'; 
  public reportName;
  public dataModel = [];
  public dataModel2 = [];
  public dataModel3 = []
  public reportID: any;
  @ViewChild(NgForm, { static: false }) form: NgForm;
  @ViewChild('picker', { static: false }) datePicker: MatDatepicker<Date>;
  public selectedDate = '';
  public reportType = '';
  constructor(
    private route: ActivatedRoute,
    private reportService: ReportService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private message_service: MessageService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.reportName = REPORT.find(o => o.reportID == params.reportID).reportName;
      this.reportID = params.reportID;
    });
    this.message_service.sendText(this.isPage); 
  }

  async onDateSelected() {

    let config = new MatDialogConfig();
    config = {
      panelClass: 'tranparent-modal',
      disableClose: true
    };
    const date = this.onDateChange(this.form.form.get('date').value);
    this.selectedDate = date;
    this.reportType = this.form.form.get('reportType').value;
    let dialogRef = this.dialog.open(LoadingComponent, config);

    try {
      if (this.reportType === '06') {
        this.dataModel = await this.reportService.getCallCenterLog('6', date);
        this.dataModel2 = await this.reportService.getCallCenterSearch(this.reportID, date, this.reportType);
        this.dataModel3 = await this.reportService.getCallCenterLog(this.reportID, date, this.reportType);
      } else if (this.reportType === '08') {
        this.dataModel = await this.reportService.getWebsiteLog('8', date);
        this.dataModel2 = await this.reportService.getWebsiteSearch(this.reportID, date, this.reportType);
        this.dataModel3 = await this.reportService.getWebsiteLog(this.reportID, date, this.reportType);
      } else {
        this.dataModel = await this.reportService.getRetailLog(this.reportType, date);
        this.dataModel2 = await this.reportService.getRetailSearch(this.reportID, date, this.reportType);
        this.dataModel3 = await this.reportService.getRetailLog(this.reportID, date, this.reportType);
      }

    } catch (e) {
      this.toastr.show(
        e,
        null,
        {
          positionClass: 'toast-top-right',
          toastComponent: ErrorToastrComponent,
          timeOut: 3000,
          tapToDismiss: false
        }
      );
    } finally {
      dialogRef.close();
    }
  }

  onDateChange(event: any) {
    const date = new Date(event)
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }

  async onCallSap() {

    let config = new MatDialogConfig();
    config = {
      panelClass: 'tranparent-modal',
      disableClose: true
    };
    let dialogRef = this.dialog.open(LoadingComponent, config);

    try {

      if (this.reportType === '06') {
        await this.reportService.callSapCallCenter(this.reportID, this.selectedDate, this.reportType);
        this.dataModel3 = await this.reportService.getCallCenterLog(this.reportID, this.selectedDate, this.reportType);
        this.dataModel = await this.reportService.getCallCenterLog('6', this.selectedDate);
      } else if (this.reportType === '08') {
        await this.reportService.callSapWebsite(this.reportID, this.selectedDate, this.reportType);
        this.dataModel = await this.reportService.getWebsiteLog('8', this.selectedDate);
        this.dataModel3 = await this.reportService.getWebsiteLog(this.reportID, this.selectedDate, this.reportType);
      } else {
        await this.reportService.callSapRetail(this.reportID, this.selectedDate, this.reportType);
        this.dataModel3 = await this.reportService.getRetailLog(this.reportID, this.selectedDate, this.reportType);
        this.dataModel = await this.reportService.getRetailLog(this.reportType, this.selectedDate);
      }

      this.dataModel2 = [];
      this.selectedDate = '';
    } catch (e) {
      this.toastr.show(
        e,
        null,
        {
          positionClass: 'toast-top-right',
          toastComponent: ErrorToastrComponent,
          timeOut: 3000,
          tapToDismiss: false
        }
      );
    } finally {
      dialogRef.close();
    }
  }

}
